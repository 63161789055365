import React from 'react'

import FAQ from 'src/components/Faq/index'

type Faq = {
  faq: {question: string; answer: string}[];
}

const FaqMeuPorquinho = ({ faq }: Faq) => {
  return (
    <div className='container pb-4 pb-md-5 pt-lg-5'>
      <div className='row mt-xl-5'>
        <div className='col-12 mb-4'>
          <h2 className='font-citrina fs-20 lh-25 fs-md-24 lh-md-30 fs-xl-40 lh-md-50 text-grayscale--500 mb-md-3'>
            Tudo o que você precisa saber sobre o Meu Porquinho
          </h2>
        </div>
        <FAQ answerData={faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} />
      </div>
    </div>
  )
}

export default FaqMeuPorquinho
