import styled from 'styled-components'
import { gray, grayscale, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const ContainerCarousel = styled.div`

  .react-multi-carousel-track {
    padding-bottom: 0;
  }
`

export const Card = styled.div`
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background: ${white};
  border: 1px solid ${grayscale[200]};
  height: 184px;
  margin-right: 16px;

  @media ${device.desktopLG} {
    padding: 10px;
  }
`

export const Equal = styled.span`
  background-color: ${gray[400]};
  border-radius: 50%;
  padding: 4px;
  width: 23px;
  height: 20px;
`
