import React, { useState } from 'react'
import SectionComponent from 'src/components/SectionComponent'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { MetasInterPigMobile } from './_metas-inter-pig-mobile'
import { MetasInterPigDesktop } from './_metas-inter-pig-desktop'

import usePageQuery from '../../pageQuery'
import { SuperAppInterProps, MetasInterPigMobileProps, IMetasInterPigProps } from '../../types'

import { ContainerDinDin, MetasTitle, Absolute } from './styles'

const ChegaDeDinDinParadoNaConta = ({ dataLayer, setDataLayer }: SuperAppInterProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const [ isIndex, setIsIndex ] = useState(0)
  const [ active, setActive ] = useState(true)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='chega-de-din-din-parado-na-conta'
      SectionStyles='pt-4 pb-5 bg-white'
      bgMobile='bgChegaDeDinDinMobile'
      bgTablet='bgChegaDeDinDinTablet'
      bgDesktopLG='bgChegaDeDinDinDesktopLG'
      bgDesktopXL='bgChegaDeDinDinDesktopXL'
      bgDesktopXXL='bgChegaDeDinDinDesktopXXL'
      backgroundSize='contain'
      backgroundPosition='center bottom -1px'
      pageDataImages={data}
      minHeight={{ sm: '579px', md: '593px', lg: '663px', xl: '740px', xxl: '750px' }}
    >
      <ContainerDinDin className='col-12 px-0 text-md-center'>
        <div className='col-12 mb-md-5 pr-0'>
          <h2 className='font-citrina text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fs-xxl-48 lh-xxl-60 fw-600 mb-3'>
            Chega de din-din parado na conta!
          </h2>
          <p className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22'>
            Descubra quanta coisa legal dá pra fazer guardando o seu dinheiro no Meu Porquinho
          </p>
        </div>
        {width < WIDTH_MD
          ? (
            <div className='col-12'>
              <DefaultCarousel
                sm={{ items: 1 }}
                md={{ items: 1 }}
                lg={{ items: 1 }}
                xl={{ items: 1 }}
              >
                {MetasInterPigMobile.map((itemMobile: MetasInterPigMobileProps, index: number) => (
                  <div className='d-flex position-relative justify-content-center' key={index}>
                    <img src={itemMobile.image} alt={itemMobile.altText} width={282} height={292} key={index} />
                  </div>
                ))}
              </DefaultCarousel>
            </div>
          )
          : (
            MetasInterPigDesktop.map((item: IMetasInterPigProps, index: number) => (
              <div key={index} className='row'>
                <Absolute className='col-md-6'>
                  { isIndex === index &&
                    <img
                      src={item.image} alt={item.altText}
                      width={width <= WIDTH_MD ? 260 : 399}
                      height={width <= WIDTH_MD ? 260 : 399}
                    />
                  }
                </Absolute>
                <div className='col-md-6 offset-md-6 col-lg-5 offset-lg-7'>
                  <MetasTitle
                    className={`${active && index === isIndex && 'active'} text-grayscale--400 fs-16 lh-20 d-inline`}
                    onClick={() => {
                      setIsIndex(index)
                      setActive(true)
                      setDataLayer(dataLayer)
                      sendDatalayerEvent({
                        section: 'dobra_05',
                        section_name: 'Chega de din-din parado na conta!',
                        element_action: 'click button',
                        element_name: item.title,
                      })
                    }}
                  >
                    <span>{item.icon}</span> {item.title}
                  </MetasTitle>
                </div>
              </div>
            ))
            )
          }
        {width >= WIDTH_MD &&
          <div className='col-md-6 offset-md-6 col-lg-5 offset-lg-7 mt-md-3'>
            <p className='text-white text-left fs-md-24 lh-md-30 font-sora mb-0'>E muito mais!</p>
          </div>
        }
      </ContainerDinDin>
    </SectionComponent>
  )
}

export default ChegaDeDinDinParadoNaConta
