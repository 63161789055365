import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import SectionComponent from 'src/components/SectionComponent'
import MeuPorquinhoSimulator from 'src/components/MeuPorquinhoSimulator'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import usePageQuery from '../../pageQuery'

import { SuperAppInterProps } from '../../types'
import { ContainerBox } from './styles'

const QuantoDesejaGuardarPorMes = ({ setOpenModal, dataLayer, setDataLayer, dobra }: SuperAppInterProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const url = 'https://intergo.app/9a5c30c1'
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='quanto-deseja-guardar-por-mes'
      bgTablet='bgQuantoDesejaGuardarTablet'
      bgDesktopLG='bgQuantoDesejaGuardarDesktopLG'
      bgDesktopXL='bgQuantoDesejaGuardarDesktopXL'
      bgDesktopXXL='bgQuantoDesejaGuardarDesktopXXL'
      backgroundSize='contain'
      pageDataImages={data}
      backgroundPosition='center center'
      RowStyles='justify-content-xl-center'
      SectionStyles='pt-5 pb-0 pt-md-0 bg-orange--extra'
      minHeight={{ sm: '891px', md: '706px', lg: '748px', xl: '812px', xxl: '812px' }}
    >
      <div className='col-12 col-md-6 col-xl-5 pr-lg-5 pr-xl-0 mb-5'>
        <h2 className='font-citrina lh-32 text-white fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fs-xxl-48 lh-xxl-60 mb-3'>
          <span className='d-xl-block'>Quanto deseja </span>guardar por mês?
        </h2>
        <p className='text-white fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 mb-4'>
          Alcance suas metas mais rápido guardando o seu dinheiro com segurança e com rendimentos maiores
          do que da poupança.
        </p>
        {width < WIDTH_MD
          ? (
            <a
              href={url}
              title='Ativar agora'
              className='btn btn--lg bg-white text-orange--extra text-none'
              onClick={() => {
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'Quanto deseja guardar por mês?',
                  element_action: 'click button',
                  element_name: 'Ativar agora',
                  redirect_url: url,
                })
              }}
            >
              Ativar agora
            </a>)
          : (
            <button
              title='Ativar agora'
              className='btn btn--lg bg-white text-orange--extra text-none mt-md-2'
              onClick={() => {
                setOpenModal && setOpenModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'Quanto deseja guardar por mês?',
                  element_action: 'click button',
                  element_name: 'Ativar agora',
                })
              }}
            >
              Ativar agora
            </button>
          )
        }
      </div>
      <ContainerBox className='col-12 col-md-6 col-xl-5 offset-xl-1 grid-desktop-xxl'>
        <MeuPorquinhoSimulator dataLayer={dataLayer} setDataLayer={setDataLayer} dobra={dobra} />
      </ContainerBox>
    </SectionComponent>
  )
}

export default QuantoDesejaGuardarPorMes
