import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import SectionComponent from 'src/components/SectionComponent'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import ReinvistaSeuCashbackJSON from '../../assets/data/reinvista-seu-cashback.json'

import { ContentType, ItemProps, SuperAppInterProps } from '../../types'
import { ContainerReinvista } from './style'

const ReinvistaOseuCashback = ({ setOpenModal, dataLayer, setDataLayer }: SuperAppInterProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()
  const url = 'https://intergo.app/9a5c30c1'
  const { title, description, cta, cards }: ContentType = ReinvistaSeuCashbackJSON

  return (
    <SectionComponent
      id='reinvista-o-seu-cashback'
      SectionStyles='py-5 bg-grayscale--100'
      RowStyles='align-items-center justify-content-between'
      minHeight={{ sm: '536px', md: '320px', lg: '386px', xl: '432px', xxl: '514px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 col-xl-6 pr-md-0'>
        <h2
          className='font-citrina fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-grayscale--400'
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {width < WIDTH_MD
          ? (
            <a
              href={url}
              title='Ativar agora'
              className='btn btn-orange--extra btn--lg fw-600 text-none mt-2'
              dangerouslySetInnerHTML={{ __html: cta.text }}
              onClick={() => {
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_08',
                  section_name: title,
                  element_action: 'click button',
                  element_name: cta.text,
                  redirect_url: url,
                })
              }}
            />
          )
          : (
            <button
              title='Ativar agora'
              className='btn btn-orange--extra btn--lg fw-600 text-none mt-2'
              dangerouslySetInnerHTML={{ __html: cta.text }}
              onClick={() => {
                setOpenModal && setOpenModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_08',
                  section_name: title,
                  element_action: 'click button',
                  element_name: cta.text,
                })
              }}
            />
          )
        }
      </div>
      <ContainerReinvista className='col-12 col-md-6'>
        <DefaultCarousel
          sm={{ items: 1, partialVisibilityGutter: 130 }}
          md={{ items: 1, partialVisibilityGutter: 70 }}
          lg={{ items: 1, partialVisibilityGutter: 198 }}
          xl={{ items: 2, partialVisibilityGutter: 18 }}
        >
          {cards.map((item: ItemProps, index: number) => (
            <div
              key={index}
              title={item.title}
              className='rounded-3 card-carousel mr-3 d-flex flex-column'
            >
              <div className='mb-2'><OrangeIcon size='MD' color='#6A6C72' icon={item.icon} /></div>
              <span className='font-sora fs-16 lh-20 text-grayscale--500 fw-600 pl-1'>{item.title}</span>
            </div>
            ),
          )}
        </DefaultCarousel>
      </ContainerReinvista>
    </SectionComponent>
  )
}

export default ReinvistaOseuCashback
