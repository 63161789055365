import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const TextContainer = styled.div`

  p {
    margin-bottom: 24px;

    @media ${device.tablet} {
      margin-bottom: 32px;
    }
    @media ${device.desktopLG} {
      padding: 0 110px;
    }
  }
`
export const TableContainer = styled.div`
  flex: 0 0 auto;
  width: 480px;

  @media ${device.tablet} {
    width: auto;
  }

  .cdb-tooltip {
    .tooltip {
      left: calc(100% - 221px);
      padding: 12px 20px 0 4px;

      @media ${device.tablet} {
        padding: 3px 20px 0 4px;
      }

      &:before {
        content: '';
        background: ${grayscale['500']};
        border-radius: 2px;
        height: 16px;
        position: absolute;
        top: -4px;
        left: calc(100% - 50px);
        width: 16px;
        transform: rotate(45deg);
      }
    }
  }

  .poupanca-tooltip {
    .tooltip {
      left:  calc(100% - 247px);
      padding: 12px 20px 0 4px;

      @media ${device.tablet} {
        padding: 3px 20px 0 4px;
      }

      &:before {
        content: '';
        background: ${grayscale['500']};
        border-radius: 2px;
        height: 16px;
        position: absolute;
        top: -4px;
        left: calc(100% - 25px);
        width: 16px;
        transform: rotate(45deg);
      }
    }
  }

  .porquinho-turbinado-tooltip {
    .tooltip {
      left:  calc(100% - 247px);
      padding: 12px 20px 0 4px;
      width: 280px;

      @media ${device.tablet} {
        padding: 3px 20px 0 4px;
      }

      &:before {
        content: '';
        background: ${grayscale['500']};
        border-radius: 2px;
        height: 16px;
        position: absolute;
        top: -4px;
        left: calc(100% - 53px);
        width: 16px;
        transform: rotate(45deg);
      }
    }
  }
  div {
    height: 60px;
    align-items: center;
    border-bottom: 1px solid ${grayscale[300]};
    text-align: right;
    padding: 4px;

    @media ${device.tablet} {
      display: flex;
      justify-content: flex-end;
    }
    &.primary {
      border-right: 1px solid ${grayscale[300]};
      justify-content: start;

      @media ${device.tablet} {
        text-align: left;
      }
    }
  }

  &.active {
    background: ${orange[100]};
  }
`

export const Card = styled.div`
  align-items: flex-start;
  margin-top: 40px;
  display: flex;
  padding: 24px;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid ${grayscale[200]};
  background: ${grayscale[100]};
  height: 161px;

  @media ${device.tablet} {
    margin-top: 32px;
    height: 270px;
  }
  @media ${device.desktopLG} {
    margin-top: 32px;
    height: 217px;
  }
  @media ${device.desktopXXL} {
    margin-top: 40px;
  }

  &.down {
    margin-top: 16px;

    @media ${device.tablet} {
      margin-top: 32px;
    }
  }
`

export const Table = styled.div`
  padding: 0 0 50px 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  @media ${device.tablet} {
    padding: 0;
    width: 100%;
    overflow-x: visible;
    overflow-y: visible;
  }
`
