import React, { useState } from 'react'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import Layout from 'src/layouts/BaseLayout'

import AcompanheOblogInterInvest from './sections/acompanhe-o-blog-inter-invest/_index'
import CarouselMeuPorquinho from './sections/carousel-acesse-o-app/_index'
import ChegaDeDinDinParadoNaConta from './sections/chega-de-din-din-parado-na-conta/_index'
import CompareEveja from './sections/compare-e-veja/_index'
import FaqMeuPorquinho from './sections/faq-meu-porquinho/_index'
import HeroGuardarDinheiroEsimples from './sections/hero-guardar-dinheiro-e-simples/_index'
import OqueEoMeuPorquinho from './sections/o-que-e-o-meu-porquinho/_index'
import OlhaQuemEstaGuardandoDinheiro from './sections/olha-quem-esta-guardando-dinheiro/_index'
import OsMelhoresBeneficios from './sections/os-melhores-beneficios/_index'
import QuantoDesejaGuardarPorMes from './sections/quanto-deseja-guardar-por-mes/_index'
import ReinvistaOseuCashback from './sections/reinvista-o-seu-cashback/_index'
import TransformeSeusPontosEmDinheiro from './sections/transforme-seus-pontos-em-dinheiro/_index'

import useDomReady from 'src/hooks/window/useDomReady'
import pageContext from './pageContext.json'
import MeuPorquinhoSection from './sections/meu-porquinho-section/_index'

const MeuPorquinho = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ typeModal, setTypeModal ] = useState<'PF' | 'PJ' | 'US'>('PF')

  const domReady = useDomReady()

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Guardar dinheiro é simples!',
    element_action: 'click button',
    element_name: 'Guardar meu dinheiro',
  })

    const modalInfos = {
      PF: {
        title: 'Meu Porquinho do Inter',
        instructions: '',
        qrCode: 'https://central-imagens.bancointer.com.br/images-without-small-versions/lp-porquinho-qrcode-pf/image.png',
      },
      PJ: {
        title: 'Porquinho PJ',
        instructions: 'PJ',
        qrCode: 'https://central-imagens.bancointer.com.br/images-without-small-versions/lp-porquinho-qrcode-pj/image.png',
      },
      US: {
        title: 'Acesse o Meu Porquinho US',
        instructions: 'US',
        qrCode: 'https://central-imagens.bancointer.com.br/images-without-small-versions/lp-porquinho-qrcode-us/image.png',
      },
    }

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title={`<span class="d-block">Acesse o</span> ${modalInfos[typeModal].title} `}
        instructions={`Aponte a câmera do celular para o QR Code abaixo que você será direcionado para o Meu Porquinho ${modalInfos[typeModal].instructions}`}
        qrCode={modalInfos[typeModal].qrCode}
        dataLayer={dataLayer}
        isWebm
      />
    </Modal>
  )
  return (
    <Layout pageContext={pageContext}>
      {openModal}
      <HeroGuardarDinheiroEsimples setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} />
      <QuantoDesejaGuardarPorMes setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} dobra='dobra_02' />
      <OqueEoMeuPorquinho setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} />
      <MeuPorquinhoSection setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} typeModal={typeModal} setTypeModal={setTypeModal} />
      <ChegaDeDinDinParadoNaConta dataLayer={dataLayer} setDataLayer={setDataLayer} />
      <OsMelhoresBeneficios setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} />
      <CompareEveja setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} />
      <ReinvistaOseuCashback setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} />
      <OlhaQuemEstaGuardandoDinheiro dataLayer={dataLayer} setDataLayer={setDataLayer} />
      <TransformeSeusPontosEmDinheiro dataLayer={dataLayer} setDataLayer={setDataLayer} />
      <CarouselMeuPorquinho />
      <AcompanheOblogInterInvest dataLayer={dataLayer} setDataLayer={setDataLayer} />
      <FaqMeuPorquinho faq={pageContext.structuredData.faq} />
    </Layout>
  )
}

export default MeuPorquinho
