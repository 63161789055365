import React, { useEffect } from 'react'

import * as S from './styles'
import * as Types from './types'
import { Theme, initTheme } from '@interco/inter-ui'
import sectionInfos from './sectionInfos.json'

import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'

import { Button } from '@interco/inter-ui/components/Button'
import { grayscale } from 'src/styles/colors'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import PagerMeuPorquinho from './components/TabPager/_index'
import { widths } from 'src/styles/breakpoints'

const MeuPorquinhoSection = ({
  setOpenModal,
  dataLayer,
  setDataLayer,
  typeModal,
  setTypeModal,
}: Types.MeuPorquinhoSectionProps) => {
  const width = useWidth()
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  const SectionDesktop = domReady && width >= widths.md && (
    <>
      <div className='container d-flex justify-content-center'>
        <S.DivPrincipal type={typeModal} className='row'>
          <S.InformationsDiv className='col-12 col-md-7 col-xl-5'>
            <PagerMeuPorquinho typeModal={typeModal} setTypeModal={setTypeModal} />
            {
              typeModal === 'US'
                ? <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/tag-novo-meu-porquinho/image.png' alt='Novo' className='mb-2' />
                : ''
            }
            <h2 className='section__title'>
              {sectionInfos.page.title[typeModal]}
            </h2>
            <p className='section__subtitle'>
              {sectionInfos.page.subtitle[typeModal]}
            </p>
            <ul className='section__benefits'>
              { sectionInfos.page.list[typeModal].map((item: Types.ListItemProps, index: number) => {
                return (
                  <li
                    className='section__benefits__item'
                    key={`item${index}`}
                  >
                    <div className='section__benefits__item__icon'>
                      <IconsSwitch
                        lib='interco'
                        icon={item.icon}
                        customPath={item.customPath}
                        size='MD'
                        color={grayscale[500]}
                        className='section__benefits__item__icon__image'
                      />
                    </div>
                    <p className='section__benefits__item__description'>
                      {item.text}
                    </p>
                  </li>
                )
              })}
            </ul>
            <Button
              fullWidth
              className='section__save__money__button'
              onClick={() => {
                setOpenModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: sectionInfos.page.title[typeModal],
                  element_action: 'click button',
                  element_name: sectionInfos.page.button.text[typeModal],
                })
              }}
            >
              {sectionInfos.page.button.text[typeModal]}
            </Button>
          </S.InformationsDiv>
          <S.InvestmentOffers className='col-md-5'>
            {sectionInfos.page.cards.map((item: Types.CardsItemProps, index: number) => {
              return (
                <S.Card
                  key={`card${index}`}
                >
                  <div className='card__icon'>
                    <img
                      src={item.icon[typeModal]}
                      alt={item.title[typeModal]}
                      className='card__icon__image'
                    />
                  </div>
                  <div className='card__texts'>
                    <h5 className='card__texts__title'>
                      {item.title[typeModal]}
                    </h5>
                    <p className='card__texts__initial__value'>
                      Comece com
                      <span className='card__texts__initial__value--highlighted'>
                        {item.moneyValue[typeModal]}
                      </span>
                    </p>
                  </div>
                </S.Card>
              )
            })}
          </S.InvestmentOffers>
        </S.DivPrincipal>
      </div>
    </>
  )

  const SectionMobile = domReady && width < widths.md && (
    <>
      <PagerMeuPorquinho typeModal={typeModal} setTypeModal={setTypeModal} />
      <div className='container d-flex justify-content-center'>
        <S.DivPrincipal type={typeModal} className='row'>
          <S.InformationsDiv className='col-12 col-md-7 col-xl-5'>
            {
              typeModal === 'US'
                ? <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/tag-novo-meu-porquinho/image.png' alt='Novo' className='mb-2' />
                : ''
            }
            <h2 className='section__title'>
              {sectionInfos.page.title[typeModal]}
            </h2>
            <p className='section__subtitle'>
              {sectionInfos.page.subtitle[typeModal]}
            </p>
            <ul className='section__benefits'>
              { sectionInfos.page.list[typeModal].map((item: Types.ListItemProps, index: number) => {
                return (
                  <li
                    className='section__benefits__item'
                    key={`item${index}`}
                  >
                    <div className='section__benefits__item__icon'>
                      <IconsSwitch
                        lib='interco'
                        icon={item.icon}
                        customPath={item.customPath}
                        size='MD'
                        color={grayscale[500]}
                        className='section__benefits__item__icon__image'
                      />
                    </div>
                    <p className='section__benefits__item__description'>
                      {item.text}
                    </p>
                  </li>
                )
              })}
            </ul>
            <S.InvestmentOffers className='col-md-5'>
              {sectionInfos.page.cards.map((item: Types.CardsItemProps, index: number) => {
                return (
                  <S.Card
                    key={`card${index}`}
                  >
                    <div className='card__icon'>
                      <img
                        src={item.icon[typeModal]}
                        alt={item.title[typeModal]}
                        className='card__icon__image'
                      />
                    </div>
                    <div className='card__texts'>
                      <h5 className='card__texts__title'>
                        {item.title[typeModal]}
                      </h5>
                      <p className='card__texts__initial__value'>
                        Comece com
                        <span className='card__texts__initial__value--highlighted'>
                          {item.moneyValue[typeModal]}
                        </span>
                      </p>
                    </div>
                  </S.Card>
                )
              })}
            </S.InvestmentOffers>

            <Button
              as='a'
              fullWidth
              className='section__save__money__button'
              href={sectionInfos.page.button.links[typeModal]}
              onClick={() => {
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: sectionInfos.page.title[typeModal],
                  element_action: 'click button',
                  element_name: sectionInfos.page.button.text[typeModal],
                  redirect_url: sectionInfos.page.button.links[typeModal],
                })
              }}
            >
              {sectionInfos.page.button.text[typeModal]}
            </Button>
          </S.InformationsDiv>
        </S.DivPrincipal>
      </div>
    </>
  )
  return (
    <S.ContentWrapper>
      {SectionMobile}
      {SectionDesktop}
    </S.ContentWrapper>
  )
}

export default MeuPorquinhoSection
