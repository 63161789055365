import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const BlogCarousel = styled.div`

  .content {
    border-radius: 16px 24px 0 0;
    height: 150px;

    @media ${device.tablet} {
      top: 31px;
      height: 210px;
    }
    @media ${device.desktopLG} {
      height: 276px;
    }
  }

  &.p-card {
    padding: 0;

    @media ${device.tablet} {
      padding-right: 24px;
    }
    @media ${device.desktopLG} {
      padding-right: 10.5px;
      padding-left: 10.5px
    }
  }

  article {
    border: 1px solid var(--gray200);
    border-radius: 16px;
    padding: 16px 24px;

    .content-tag {
      .category {
        height: 16px;
        border-radius: 4px;
        padding: 2px 4px;
        margin-right: 16px;
      }
      .tag {
        border-radius: 4px;
        padding: 2px 4px;
        background-color: var(--gray100)
      }
    }

  }

  .title-post {
    font-family: 'Sora';

    @media ${device.tablet} {
      letter-spacing: 0;
    }
    @media ${device.desktopLG} {
      letter-spacing: 1;
    }
  }
`
export const ContentButton = styled.div`
  text-align: center;
  width: 100%;
  display: block;

  a {
    border: 1px solid ${orange.extra};
    padding-top: 15px;
    margin: auto;
  }
`
