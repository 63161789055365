import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

export const ContainerDinDin = styled.div`

  .react-multi-carousel-dot--active button {
      background: ${white};
  }
  .react-multiple-carousel__arrow {
    svg {
      fill: ${white}
    }
  }
`

export const MetasTitle = styled.p`
  padding: 10px 16px;
  align-items: center;
  float: left;
  border-radius: 8px;
  background: ${white};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.20);
  cursor: pointer;

  &:hover {
    background: ${orange[100]};
    color: ${grayscale[400]};
  }

  &.active {
    color: ${white};
    background: ${orange.extra};
  }
`

export const Absolute = styled.div`
  position: absolute;
  top: 160px;
  left: 0;

  @media ${device.desktopLG} {
    left: 70px;
    top: 120px;
  }
`
