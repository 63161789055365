import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import SimulacaoPontosInterloop from '../../assets/data/simulacao-pontos-interloop.json'
import usePageQuery from '../../pageQuery'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import InterLoopSimulador from 'src/components/InterLoopSimulador'
import InterLoop from '@interco/icons/build-v4/orangeds/MD/inter-loop'

import { Link } from 'gatsby'
import { SuperAppInterProps } from '../../types'

import * as S from './styles'
import { orange } from 'src/styles/colors'

type InterLoopCard = {
  pontos: string;
  reais: string;
}

const TransformeSeusPontosEmDinheiro = ({ dataLayer, setDataLayer }: SuperAppInterProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='olha-quem-esta-guardando-dinheiro'
      SectionStyles='bg-white'
      bgMobile='backroundTransformeSeusPontosEmDinheiroMobile'
      bgTablet='backroundTransformeSeusPontosEmDinheiroMD'
      bgDesktopLG='backroundTransformeSeusPontosEmDinheiroLG'
      bgDesktopXL='backroundTransformeSeusPontosEmDinheiroXL'
      bgDesktopXXL='backroundTransformeSeusPontosEmDinheiroXXL'
      backgroundSize='cover'
      pageDataImages={data}
      RowStyles='justify-content-md-start'
      backgroundPosition='center bottom -1px'
      minHeight={{ sm: '859px', md: '411px', lg: '484px', xl: '568px' }}
    >
      <div className='col-12 col-md-6 mb-3 mb-md-0'>
        <h2 className='font-citrina fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4'>Transforme seus <span className='d-lg-block'>pontos em dinheiro</span></h2>
        <InterLoopSimulador />
      </div>
      <div className='col-12 col-md-6'>
        <p className='fs-16 lh-20 fs-md-14 lh-md-16 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-25 fw-400 text-grayscale--500 font-sora mb-0'>
          Se você faz parte do programa de pontos do Inter Loop e tem mais de 200 pontos, pode trocar por uma graninha no Meu Porquinho.👇
        </p>
        <DefaultCarousel
          sm={{ items: 2, partialVisibilityGutter: 30 }}
          md={{ items: 2, partialVisibilityGutter: 50 }}
          lg={{ items: 4 }}
          xl={{ items: 4 }}
          notShowDots
          notShowArrows
        >
          {SimulacaoPontosInterloop.map((item: InterLoopCard) => (
            <S.Card
              className='d-flex position-relative justify-content-center flex-column'
              key={item.pontos}
            >
              <div className='d-flex flex-column align-items-center'>
                <div className='mb-3'>
                  <InterLoop color={orange.extra} width={32} height={32} />
                </div>
                <span className='fs-24 lh-30 fw-600 font-sora text-grayscale--500'>{item.pontos}</span>
                <span className='fs-16 lh-20 fw-400 font-sora text-grayscale--500'>pontos</span>
                <div className='py-2'>
                  <S.Equal className='fs-16 lh-20 fw-600 font-sora text-grayscale--400 d-flex flex-column align-items-center justify-content-center'>=</S.Equal>
                </div>
                <p
                  className='fs-16 lh-20 fw-600 font-sora text-green--400 mb-0'
                  dangerouslySetInnerHTML={{ __html: item.reais }}
                />
              </div>
            </S.Card>
          ))}
        </DefaultCarousel>
        <Link
          to='/pra-voce/cartoes/programa-de-pontos/'
          title='Saiba Mais'
          className='btn btn--lg bg-orange--base text-white text-none mw-100'
          onClick={() => {
            setDataLayer(dataLayer)
            sendDatalayerEvent({
              section: 'dobra_10',
              section_name: 'Guardar dinheiro é simples!',
              element_action: 'click button',
              element_name: 'Saiba Mais',
              redirect_url: '/pra-voce/cartoes/programa-de-pontos/',
            })
          }}
        >
          Saiba Mais
        </Link>
      </div>
    </SectionComponent>
  )
}

export default TransformeSeusPontosEmDinheiro
