import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import useWidth from 'src/hooks/window/useWidth'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { SuperAppInterProps } from '../../types'
import { WIDTH_MD } from 'src/utils/breakpoints'
import ToolTip from 'src/components/ToolTipOnMouseOver/index'
import usePageQuery from '../../pageQuery'
import Note from '../../assets/images/note-detail.png'

import { TextContainer, TableContainer, Card, Table } from './styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const CompareEveja = ({ setOpenModal, dataLayer, setDataLayer }: SuperAppInterProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://intergo.app/9a5c30c1'

  return (
    <SectionComponent
      id='compare-e-veja'
      SectionStyles='py-5 pb-xl-4 bg-white'
      bgMobile='bgCompareEvejaMobile'
      bgTablet='bgCompareEvejaTablet'
      bgDesktopLG='bgCompareEvejaDesktopLG'
      bgDesktopXL='bgCompareEvejaDesktopXL'
      bgDesktopXXL='bgCompareEvejaDesktopXXL'
      backgroundSize='contain'
      backgroundPosition='center top'
      pageDataImages={data}
      minHeight={{ sm: '1164px', md: '983px', lg: '1023px', xl: '1127px', xxl: '1137px' }}
    >
      <TextContainer className='col-md-12 px-md-0 text-md-center'>
        <h2 className='font-citrina text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 mb-3'>
          Compare e veja o que é melhor para você
        </h2>
        <p className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20'>
          Com o Meu Porquinho, seu dinheiro rende no piloto automático, de forma simples, e você
          pode resgatar quando precisar.
        </p>
        {width < WIDTH_MD
          ? (
            <a
              href={url}
              title='Guardar meu dinheiro'
              className='btn btn--lg bg-orange--base text-white text-none'
              onClick={() => {
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_07',
                  section_name: 'Compare e veja o que é melhor para você',
                  element_action: 'click button',
                  element_name: 'Guardar meu dinheiro',
                  redirect_url: url,
                })
              }}
            >
              Guardar meu dinheiro
            </a>)
          : (
            <button
              title='Guardar meu dinheiro'
              className='btn btn--lg bg-orange--base text-white text-none'
              onClick={() => {
                setOpenModal && setOpenModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_07',
                  section_name: 'Compare e veja o que é melhor para você',
                  element_action: 'click button',
                  element_name: 'Guardar meu dinheiro',
                })
              }}
            >
              Guardar meu dinheiro
            </button>
          )
        }
      </TextContainer>
      <Table className=''>
        <TableContainer className='col-md-12 d-flex mt-5 px-0'>
          <div className='col-md-3 py-md-5 primary d-flex align-items-center justify-content-end justify-content-md-start py-5'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Características</strong>
          </div>
          <div className='col-md-3 py-md-5 d-flex align-items-center justify-content-end py-5'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'><span className='d-block'>CDB Meu</span> Porquinho</strong>
          </div>
          <div className='col-md-3 py-md-5 d-flex align-items-center justify-content-end py-5'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>LCI  Porquinho</strong>
          </div>
          <div className='col-md-3 py-md-5 d-flex align-items-center justify-content-end py-5'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Poupança</strong>
          </div>
        </TableContainer>
        <TableContainer className='col-md-12 d-flex px-0'>
          <div className='col-md-3 primary d-flex align-items-center justify-content-end justify-content-md-start'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Investimento</strong>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>R$ 1,00</span>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>R$ 1,00</span>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>R$ 0,01</span>
          </div>
        </TableContainer>
        <TableContainer className='col-md-12 d-flex px-0 active'>
          <div className='col-md-3 primary d-flex align-items-center justify-content-end justify-content-md-start'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Rendimentos</strong>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>
              Até 100% do CDI
            </span>
          </div>
          <div className='col-md-3 d-flex porquinho-turbinado-tooltip align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block mr-2'>
              Até 95% do CDI
            </span>
            <ToolTip text='Equivale a um CDB de até 111,76% do CDI' iconTooltip='help' sizeIcon='MD' colorIcon='#FF7A00' />
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>
              Até 70% da Selic
            </span>
          </div>
        </TableContainer>
        <TableContainer className='col-md-12 d-flex px-0'>
          <div className='col-md-3 primary d-flex align-items-center justify-content-end justify-content-md-start'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Frequência</strong>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>
              <span className='d-block d-lg-inline'>Rende</span> todo dia
            </span>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>
              <span className='d-block d-lg-inline'>Rende</span> todo dia
            </span>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>
              <span className='d-block d-lg-inline'>Rende uma</span> vez por mês
            </span>
          </div>
        </TableContainer>
        <TableContainer className='col-md-12 d-flex px-0'>
          <div className='col-md-3 primary d-flex align-items-center justify-content-end justify-content-md-start'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Resgate</strong>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Imediato</span>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>360 dias</span>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Imediato</span>
          </div>
        </TableContainer>
        <TableContainer className='col-md-12 d-flex px-0'>
          <div className='col-md-3 primary d-flex align-items-center justify-content-end justify-content-md-start'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Risco</strong>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Muito Baixo</span>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block d-flex justify-content-end'>Muito Baixo</span>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Muito Baixo</span>
          </div>
        </TableContainer>
        <TableContainer className='col-md-12 d-flex px-0'>
          <div className='col-md-3 primary d-flex align-items-center justify-content-end justify-content-md-start'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Imposto de Renda</strong>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Regressivo</span>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block d-flex justify-content-end'>Não</span>
          </div>
          <div className='col-md-3 d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block d-flex justify-content-end'>Não</span>
          </div>
        </TableContainer>
        <TableContainer className='col-md-12 d-flex px-0'>
          <div className='col-md-3 primary d-flex align-items-center justify-content-end justify-content-md-start'>
            <strong className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block'>Garantia</strong>
          </div>
          <div className='col-md-3 cdb-tooltip d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block mr-2'><span className='d-block d-lg-inline'>Até</span> 250 mil</span>
            <ToolTip text='FGC - Fundo Garantidor de Crédito' iconTooltip='help' sizeIcon='MD' colorIcon='#FF7A00' />
          </div>
          <div className='col-md-3 cdb-tooltip d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block mr-2'><span className='d-block d-lg-inline'>Até</span> 250 mil</span>
            <ToolTip text='FGC - Fundo Garantidor de Crédito' iconTooltip='help' sizeIcon='MD' colorIcon='#FF7A00' />
          </div>
          <div className='col-md-3 poupanca-tooltip d-flex align-items-center justify-content-end'>
            <span className='fs-12 lh-16 fs-md-18 lh-md-22 text-grayscale--500 d-block mr-2'><span className='d-block d-lg-inline'>Até</span> 250 mil</span>
            <ToolTip text='FGC - Fundo Garantidor de Crédito' iconTooltip='help' sizeIcon='MD' colorIcon='#FF7A00' />
          </div>
        </TableContainer>
      </Table>
      <div className='row'>
        <div className='col-md-4'>
          <Card className='col-12'>
            <div>
              <img src={Note} />
            </div>
            <div>
              <h3 className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 d-block mb-2'>
                CDB Meu Porquinho
              </h3>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-grayscale--400'>
                Oferece rendimentos superiores aos da poupança, sendo uma opção interessante para quem busca
                rentabilidade com disponibilidade imediata.
              </p>
            </div>
          </Card>
        </div>
        <div className='col-md-4'>
          <Card className='col-12 down'>
            <div>
              <OrangeIcon size='MD' color='#6A6C72' icon='receipt' />
            </div>
            <div>
              <h3 className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 d-block mb-2'>
                LCI Meu Porquinho
              </h3>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-grayscale--400'>
                Trata-se de um investimento em operações de crédito imobiliário que proporciona estabilidade e segurança.
              </p>
            </div>
          </Card>
        </div>
        <div className='col-md-4'>
          <Card className='col-12 down'>
            <div>
              <OrangeIcon size='MD' color='#6A6C72' icon='resumo-de-pedido' />
            </div>
            <div>
              <h3 className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 d-block mb-2'>
                Poupança
              </h3>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-grayscale--400'>
                Um investimento fácil para quem quer começar uma reserva financeira. Sem valor mínimo de aplicação
                e sem data de resgate.
              </p>
            </div>
          </Card>
        </div>
      </div>
    </SectionComponent>
  )
}

export default CompareEveja
