import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { WIDTH_MD, WIDTH_XL } from 'src/utils/breakpoints'
import { Link } from 'gatsby'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Home from '@interco/icons/build-v4/orangeds/MD/home'

import usePageQuery from '../../pageQuery'
import headerJson from '../../assets/data/header.json'

import { SuperAppInterProps } from '../../types'

const HeroGuardarDinheiroEsimples = ({ setOpenModal, dataLayer, setDataLayer }: SuperAppInterProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://intergo.app/9a5c30c1'

  return (
    <SectionComponent
      id='guardar-dinheiro-e-simples'
      bgMobile='bgHeroMeuPorquinhoMobile'
      bgTablet='bgHeroMeuPorquinhoTablet'
      bgDesktopLG='bgHeroMeuPorquinhoDesktopLG'
      bgDesktopXL='bgHeroMeuPorquinhoDesktopXL'
      bgDesktopXXL='bgHeroMeuPorquinhoDesktopXXL'
      backgroundSize={width <= WIDTH_XL ? 'contain' : 'cover'}
      backgroundPosition='center bottom'
      pageDataImages={data}
      SectionStyles='py-5 bg-white align-items-end align-items-md-center'
      minHeight={{ sm: '297px', md: '416px', lg: '504px', xl: '648px', xxl: '676px' }}
    >
      <div className='col-12 col-md-6 col-xl-5 col-xxl-4'>
        <div className='bread'>
          <Link
            to='/'
            className='d-md-inline'
          ><Home height={24} width={24} color='#6A6E81' />
          </Link>
          <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
          <Link
            to='/pra-voce/investimentos/'
            className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
          >
            {headerJson.breadcrumb[0]}
          </Link>
          <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
          <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
        </div>
        <h1 className='font-citrina text-grayscale--500 fs-24 lh-30 lh-lg-40 fs-xl-40 lh-xl-50 fs-lg-32 fs-xxl-48 lh-xxl-60 fw-600 mb-3'>
          <span className='d-md-block'>Guardar dinheiro</span> é simples!
        </h1>
        <p className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 mb-4'>
          Com o Meu Porquinho do Inter, você não precisa mais juntar dinheiro no colchão, na caixinha ou no
          cofrinho. Comece a partir de R$ 1,00.
        </p>
        {width < WIDTH_MD
          ? (
            <a
              href={url}
              title='Guardar meu dinheiro'
              className='btn btn--lg bg-orange--base text-white text-none'
              onClick={() => {
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Guardar dinheiro é simples!',
                  element_action: 'click button',
                  element_name: 'Guardar meu dinheiro',
                  redirect_url: url,
                })
              }}
            >
              Guardar meu dinheiro
            </a>)
          : (
            <button
              title='Guardar meu dinheiro'
              className='btn btn--lg bg-orange--base text-white text-none mt-md-2'
              onClick={() => {
                setOpenModal && setOpenModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Guardar dinheiro é simples!',
                  element_action: 'click button',
                  element_name: 'Guardar meu dinheiro',
                })
              }}
            >
              Guardar meu dinheiro
            </button>
          )
        }
      </div>
      {width >= WIDTH_MD &&
        <div className='col-md-6 col-lg-5'>
          <video
            preload='none'
            loop
            autoPlay
            muted
            playsInline
            width={width <= WIDTH_XL ? 360 : 456}
            height={width <= WIDTH_XL ? 360 : 456}
          >
            <source src='https://central-imagens.bancointer.com.br/gif/investimentos-gif-hero/arquivo.webm' type='video/webm' />
          </video>
        </div>
      }
    </SectionComponent>
  )
}

export default HeroGuardarDinheiroEsimples
