
import ViagensDaFamiliaMobile from '../../assets/images/metas-inter-pig/inter-pig-viagens-da-familia-mobile.svg'
import FazerUmCursoMobile from '../../assets/images/metas-inter-pig/inter-pig-fazer-um-curso-mobile.svg'
import ReformaDaCasaMobile from '../../assets/images/metas-inter-pig/inter-pig-reforma-da-casa-mobile.svg'
import TrocarDeSmartphoneMobile from '../../assets/images/metas-inter-pig/inter-pig-troca-de-smartphone-mobile.svg'
import GuardaDinheiroMobile from '../../assets/images/metas-inter-pig/inter-pig-guardar-dinheiro-para-casar-mobile.svg'
import FazerInterCambioMobile from '../../assets/images/metas-inter-pig/inter-pig-fazer-intercambio-mobile.svg'
import TrocarDeCarroMobile from '../../assets/images/metas-inter-pig/inter-pig-trocar-de-carro-mobile.svg'

export const MetasInterPigMobile = [
  {
    image: FazerInterCambioMobile,
    altText: 'Inter Pig representando a estátua da liberdade',
  },
  {
    image: TrocarDeCarroMobile,
    altText: 'Inter Pig com os braços cruzados encostado em um carro laranja',
  },
  {
    image: ViagensDaFamiliaMobile,
    altText: 'Inter Pig com mochila preta nas costas pronto para viajar',
  },
  {
    image: ReformaDaCasaMobile,
    altText: 'Inter Pig com uma caixa na mão ajudando na reforma da casa',
  },
  {
    image: TrocarDeSmartphoneMobile,
    altText: 'Inter Pig fazendo selfie com um smartphone',
  },
  {
    image: GuardaDinheiroMobile,
    altText: 'Inter Pig apaixonado com coraçãozinho vermelho no olho',
  },
  {
    image: FazerUmCursoMobile,
    altText: 'Inter Pig lendo um livro com a capa rosa',
  },
]
