export const tabItem = {
  width: '33%',
  display: 'flex',
  justifyContent: 'center',
}

export const tabList = {
  display: 'flex',
  justifyContent: 'space-evenly',
}

export const tabPanel = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  padding: '12px',
}
