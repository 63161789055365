
import React from 'react'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import SectionComponent from 'src/components/SectionComponent'

import pageText from '../../assets/data/container-blog.json'
import usePageQuery from '../../pageQuery'
import { SuperAppInterProps } from '../../types'

import { orange } from 'src/styles/colors'
import { BlogCarousel, ContentButton } from './style'
import { WIDTH_MD } from 'src/utils/breakpoints'

import Link from 'src/components/GatsbyLinkWrapper/index'

type BlogArticleType = {
  categoria: string;
  tag: string;
  image: string;
  title: string;
  data: string;
  description: string;
  link: string;
  alt: string;
}

const AcompanheOblogInterInvest = ({ dataLayer, setDataLayer }: SuperAppInterProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='acompanhe-o-blog-inter-invest'
      SectionStyles='py-5 bg-white align-items-md-start'
      RowStyles='pt-xl-4'
      bgMobile='bgTopBlogMeuPorquinhoMobile'
      bgTablet='bgTopBlogMeuPorquinhoTablet'
      bgDesktopLG='bgTopBlogMeuPorquinhoDesktopLG'
      bgDesktopXL='bgTopBlogMeuPorquinhoDesktopXL'
      bgDesktopXXL='bgTopBlogMeuPorquinhoDesktopXXL'
      backgroundSize='initial'
      pageDataImages={data}
      backgroundPosition='center top -2px'
      minHeight={{ xl: 'auto' }}
    >
      <div className='col-12 mb-4 mb-md-3 mt-md-4 mt-lg-5'>
        <h2 className='font-citrina fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 text-center'>
          {pageText.title}
        </h2>
        <p className='fs-14 lh-16 fs-md-16 lh-md-20 fs-xl-24 lh-xl-30 text-grayscale--500 text-center mt-4 mt-md-2'>
          {pageText.description}
        </p>
      </div>
      {
        pageText.blogContent.map((blogArticleContent: BlogArticleType, index: number) => (
          <BlogCarousel key={index} className='col-12 col-md-4 p-card mt-md-2 mb-4'>
            <a
              data-blog={blogArticleContent.title}
              href={blogArticleContent.link}
              title={`Leia mais em ${blogArticleContent.link}`}
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_12',
                  section_name: pageText.title,
                  element_action: 'click button',
                  element_name: blogArticleContent.title,
                  redirect_url: blogArticleContent.link,
                })
              }}
            >
              <article className='col-12'>

                <div className='content-tag'>
                  <span className='font-sora fs-12 lh-15 fw-600 text-white bg-orange--extra category'>
                    {blogArticleContent.categoria}
                  </span>
                  <span className='font-sora fs-12 lh-15 fw-600 text-grayscale--500 tag'>
                    {blogArticleContent.tag}
                  </span>
                </div>

                <h3 className='font-sora fs-16 lh-20 fs-md-20 lh-md-25 fw-600 mt-3 mb-2 mb-lg-0 title-post text-grayscale--500 title'>
                  {blogArticleContent.title}
                </h3>
                <span className='fs-12 lh-15 text-grayscale--300 fw-600'>
                  {blogArticleContent.data}
                </span>
                {width > WIDTH_MD &&
                  <>
                    <p className='fs-lg-16 lh-lg-20 mb-2 fw-400 mt-3 text-grayscale--500'>{blogArticleContent.description}</p>
                    <div className='text-lg-right'>
                      <span className='fs-14 lh-17 fw-400 text-orange--extra mt-xl-3 mr-lg-2'>{pageText.readMore}</span>
                      <OrangeDsIcon size='MD' icon='arrow-right' color={orange.extra} />
                    </div>
                  </>
                    }

              </article>
            </a>
          </BlogCarousel>
        ))
      }
      <ContentButton>
        <Link
          to='https://blog.inter.co/para-simplificar-a-vida/investir/'
          className='btn btn--lg bg-transparent text-none text-orange--extra d-block mt-4 rounded-2 fs-14 fw-700'
        >
          Acessar mais conteúdos
        </Link>
      </ContentButton>
    </SectionComponent>
  )
}

export default AcompanheOblogInterInvest
