import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const ContainerDinDin = styled.div`

  .react-multi-carousel-dot--active button {
      background: ${white};
  }
  .react-multiple-carousel__arrow {
    svg {
      fill: ${white}
    }
  }
`

export const Card = styled.div`
  padding: 24px;
  gap: 16px;
  border-radius: 16px;
  background: ${white};
  border: 1px solid ${grayscale[200]};
  min-height: 215px;
  margin-right: 16px;

  @media ${device.tablet} {
    width: 222px;
    min-height: 220px;
    text-align: left;
    margin-bottom: 16px;

    :nth-child(3n) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media ${device.desktopLG} {
    :nth-child(3n) {
      margin-right: 16px;
    }
    :nth-child(4n) {
      margin-right: 0;
    }
  }
  @media ${device.desktopXL} {
    width: 264px;
    min-height: 200px;
  }
  @media ${device.desktopXXXL} {
    width: 277px;

    :nth-child(4n) {
      margin-right: 16px;
    }
  }
`
