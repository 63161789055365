import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bgHeroMeuPorquinhoMobile: imageSharp(fluid: {originalName: { regex: "/bg-hero-meu-porquinho-mobile/" }}) {
        fluid(maxHeight: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgHeroMeuPorquinhoTablet: imageSharp(fluid: {originalName: { regex: "/bg-hero-meu-porquinho-tablet/" }}) {
        fluid(maxHeight: 768, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgHeroMeuPorquinhoDesktopLG: imageSharp(fluid: {originalName: { regex: "/bg-hero-meu-porquinho-desktop-lg/" }}) {
        fluid(maxHeight: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgHeroMeuPorquinhoDesktopXL: imageSharp(fluid: {originalName: { regex: "/bg-hero-meu-porquinho-desktop-xl/" }}) {
        fluid(maxHeight: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgHeroMeuPorquinhoDesktopXXL: imageSharp(fluid: {originalName: { regex: "/bg-hero-meu-porquinho-desktop-xxl/" }}) {
        fluid(maxHeight: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgOqueEoPorquinhoMobile: imageSharp(fluid: {originalName: { regex: "/bg-o-que-e-o-porquinho-mobile/" }}) {
        fluid(maxHeight: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgOqueEoPorquinhoTablet: imageSharp(fluid: {originalName: { regex: "/bg-o-que-e-o-porquinho-tablet/" }}) {
        fluid(maxHeight: 768, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgOqueEoPorquinhoDesktopLG: imageSharp(fluid: {originalName: { regex: "/bg-o-que-e-o-porquinho-desktop-lg/" }}) {
        fluid(maxHeight: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgOqueEoPorquinhoDesktopXL: imageSharp(fluid: {originalName: { regex: "/bg-o-que-e-o-porquinho-desktop-xl/" }}) {
        fluid(maxHeight: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgOqueEoPorquinhoDesktopXXL: imageSharp(fluid: {originalName: { regex: "/bg-o-que-e-o-porquinho-desktop-xxl/" }}) {
        fluid(maxHeight: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      oQueEoMeuPorquinho: imageSharp(fluid: {originalName: { regex: "/o-que-e-o-meu-porquinho/" }}) {
        fluid(maxHeight: 505, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgChegaDeDinDinMobile: imageSharp(fluid: {originalName: { regex: "/bg-chega-de-din-din-mobile/" }}) {
        fluid(maxHeight: 540, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgChegaDeDinDinTablet: imageSharp(fluid: {originalName: { regex: "/bg-chega-de-din-din-tablet/" }}) {
        fluid(maxHeight: 768, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgChegaDeDinDinDesktopLG: imageSharp(fluid: {originalName: { regex: "/bg-chega-de-din-din-desktop-lg/" }}) {
        fluid(maxHeight: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgChegaDeDinDinDesktopXL: imageSharp(fluid: {originalName: { regex: "/bg-chega-de-din-din-desktop-xl/" }}) {
        fluid(maxHeight: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgChegaDeDinDinDesktopXXL: imageSharp(fluid: {originalName: { regex: "/bg-chega-de-din-din-desktop-xxl/" }}) {
        fluid(maxHeight: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgCompareEvejaMobile: imageSharp(fluid: {originalName: { regex: "/bg-compare-e-veja-mobile/" }}) {
        fluid(maxHeight: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgCompareEvejaTablet: imageSharp(fluid: {originalName: { regex: "/bg-compare-e-veja-tablet/" }}) {
        fluid(maxHeight: 768, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgCompareEvejaDesktopLG: imageSharp(fluid: {originalName: { regex: "/bg-compare-e-veja-desktop-lg/" }}) {
        fluid(maxHeight: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgCompareEvejaDesktopXL: imageSharp(fluid: {originalName: { regex: "/bg-compare-e-veja-desktop-xl/" }}) {
        fluid(maxHeight: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgCompareEvejaDesktopXXL: imageSharp(fluid: {originalName: { regex: "/bg-compare-e-veja-desktop-xxl/" }}) {
        fluid(maxHeight: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      iconMariana: imageSharp(fluid: {originalName: { regex: "/icon-mariana/" }}) {
        fluid(maxHeight: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      iconAndre: imageSharp(fluid: {originalName: { regex: "/icon-andre/" }}) {
        fluid(maxHeight: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      iconLuiz: imageSharp(fluid: {originalName: { regex: "/icon-luiz/" }}) {
        fluid(maxHeight: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      iconBarbara: imageSharp(fluid: {originalName: { regex: "/icon-barbara/" }}) {
        fluid(maxHeight: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      iconCarmen: imageSharp(fluid: {originalName: { regex: "/icon-carmen/" }}) {
        fluid(maxHeight: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgOlhaQuemEstaGuardandoDinheiroMobile: imageSharp(fluid: {originalName: { regex: "/bg-olha-quem-esta-guardando-dinheiro-mobile/" }}) {
        fluid(maxHeight: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgOlhaQuemEstaGuardandoDinheiroTablet: imageSharp(fluid: {originalName: { regex: "/bg-olha-quem-esta-guardando-dinheiro-tablet/" }}) {
        fluid(maxHeight: 768, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgOlhaQuemEstaGuardandoDinheiroMDesktopLG: imageSharp(fluid: {originalName: { regex: "/bg-olha-quem-esta-guardando-dinheiro-desktop-lg/" }}) {
        fluid(maxHeight: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgOlhaQuemEstaGuardandoDinheiroDesktopXL: imageSharp(fluid: {originalName: { regex: "/bg-olha-quem-esta-guardando-dinheiro-desktop-xl/" }}) {
        fluid(maxHeight: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgOlhaQuemEstaGuardandoDinheiroXXL: imageSharp(fluid: {originalName: { regex: "/bg-olha-quem-esta-guardando-dinheiro-desktop-xxl/" }}) {
        fluid(maxHeight: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      acesseOappInter: imageSharp(fluid: {originalName: { regex: "/acesse-o-app-inter/" }}) {
        fluid(maxHeight: 620, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      escolhaMeuPorquinho: imageSharp(fluid: {originalName: { regex: "/escolha-meu-porquinho/" }}) {
        fluid(maxHeight: 620, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cliqueEmGuardarDinheiro: imageSharp(fluid: {originalName: { regex: "/clique-em-guardar-dinheiro/" }}) {
        fluid(maxHeight: 620, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      selecioneOvalorQueDeseja: imageSharp(fluid: {originalName: { regex: "/selecione-o-valor-que-deseja/" }}) {
        fluid(maxHeight: 620, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      prontoAgoraEsoAguardar: imageSharp(fluid: {originalName: { regex: "/pronto-agora-e-so-aguardar/" }}) {
        fluid(maxHeight: 620, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      oQueEoMeuPorquinhoBlog: imageSharp(fluid: {originalName: { regex: "/inter-pig-meu-porquinho/" }}) {
        fluid(maxHeight: 376, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      comoComecarAinvestirBlog: imageSharp(fluid: {originalName: { regex: "/como-comecar-a-investir-blog/" }}) {
        fluid(maxHeight: 376, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      investimentoEmAcoesParaIniciantes: imageSharp(fluid: {originalName: { regex: "/investimento-em-acoes-para-iniciantes/" }}) {
        fluid(maxHeight: 376, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgTopBlogMeuPorquinhoMobile: imageSharp(fluid: {originalName: { regex: "/bg-top-blog-meu-porquinho-mobile/" }}) {
        fluid(maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgTopBlogMeuPorquinhoTablet: imageSharp(fluid: {originalName: { regex: "/bg-top-blog-meu-porquinho-tablet/" }}) {
        fluid(maxHeight: 768, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgTopBlogMeuPorquinhoDesktopLG: imageSharp(fluid: {originalName: { regex: "/bg-top-blog-meu-porquinho-desktop-lg/" }}) {
        fluid(maxHeight: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgTopBlogMeuPorquinhoDesktopXL: imageSharp(fluid: {originalName: { regex: "/bg-top-blog-meu-porquinho-desktop-xl/" }}) {
        fluid(maxHeight: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgTopBlogMeuPorquinhoDesktopXXL: imageSharp(fluid: {originalName: { regex: "/bg-top-blog-meu-porquinho-desktop-xxl/" }}) {
        fluid(maxHeight: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgQuantoDesejaGuardarTablet: imageSharp(fluid: {originalName: { regex: "/bg-quanto-deseja-guardar-tablet/" }}) {
        fluid(maxHeight:768, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgQuantoDesejaGuardarDesktopLG: imageSharp(fluid: {originalName: { regex: "/bg-quanto-deseja-guardar-desktop-lg/" }}) {
        fluid(maxHeight: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgQuantoDesejaGuardarDesktopXL: imageSharp(fluid: {originalName: { regex: "/bg-quanto-deseja-guardar-desktop-xl/" }}) {
        fluid(maxHeight: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgQuantoDesejaGuardarDesktopXXL: imageSharp(fluid: {originalName: { regex: "/bg-quanto-deseja-guardar-desktop-xxl/" }}) {
        fluid(maxHeight: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backroundTransformeSeusPontosEmDinheiroMobile: imageSharp(fluid: {originalName: { regex: "/background-transforme-seus-pontos-em-dinheiro-mobile/" }}) {
        fluid(maxHeight: 859, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backroundTransformeSeusPontosEmDinheiroMD: imageSharp(fluid: {originalName: { regex: "/background-transforme-seus-pontos-em-dinheiro-md/" }}) {
        fluid(maxHeight: 768, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backroundTransformeSeusPontosEmDinheiroLG: imageSharp(fluid: {originalName: { regex: "/background-transforme-seus-pontos-em-dinheiro-lg/" }}) {
        fluid(maxHeight: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backroundTransformeSeusPontosEmDinheiroXL: imageSharp(fluid: {originalName: { regex: "/background-transforme-seus-pontos-em-dinheiro-xl/" }}) {
        fluid(maxHeight: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backroundTransformeSeusPontosEmDinheiroXXL: imageSharp(fluid: {originalName: { regex: "/background-transforme-seus-pontos-em-dinheiro-xxl/" }}) {
        fluid(maxHeight: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
