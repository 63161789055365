
import FazerInterCambioDesktop from '../../assets/images/metas-inter-pig/inter-pig-fazer-intercambio.svg'
import TrocarDeCarroDesktop from '../../assets/images/metas-inter-pig/inter-pig-trocar-de-carro.svg'
import ViagensDaFamiliaDesktop from '../../assets/images/metas-inter-pig/inter-pig-viagens-da-familia.svg'
import ReformaDaCasaDesktop from '../../assets/images/metas-inter-pig/inter-pig-reforma-da-casa.svg'
import TrocarDeSmartphoneDesktop from '../../assets/images/metas-inter-pig/inter-pig-troca-de-smartphone.svg'
import GuardaDinheiroDesktop from '../../assets/images/metas-inter-pig/inter-pig-guardar-dinheiro-para-casar.svg'
import FazerUmCursoDesktop from '../../assets/images/metas-inter-pig/inter-pig-fazer-um-curso.svg'

export const MetasInterPigDesktop = [
  {
    image: FazerInterCambioDesktop,
    altText: 'Inter Pig representando a estátua da liberdade',
    icon: '✈️',
    title: 'Fazer Intercâmbio',
  },
  {
    image: TrocarDeCarroDesktop,
    altText: 'Inter Pig com os braços cruzados encostado em um carro laranja',
    icon: '🚗',
    title: 'Trocar de carro',
  },
  {
    image: ViagensDaFamiliaDesktop,
    altText: 'Inter Pig com mochila preta nas costas pronto para viajar',
    icon: '⛱',
    title: 'Viagens da Família',
  },
  {
    image: ReformaDaCasaDesktop,
    altText: 'Inter Pig com uma caixa na mão ajudando na reforma da casa',
    icon: '🏠',
    title: 'Reforma da Casa',
  },
  {
    image: TrocarDeSmartphoneDesktop,
    altText: 'Inter Pig fazendo selfie com um smartphone',
    icon: '📱',
    title: 'Trocar de smartphone',
  },
  {
    image: GuardaDinheiroDesktop,
    altText: 'Inter Pig apaixonado com coraçãozinho vermelho no olho',
    icon: '💖',
    title: 'Guardar dinheiro para casar',
  },
  {
    image: FazerUmCursoDesktop,
    altText: 'Inter Pig lendo um livro com a capa rosa',
    icon: '📚',
    title: 'Fazer um curso',
  },
]
