import React, { useState, ChangeEvent } from 'react'
import { convertFloatToCurrencyString } from 'src/shared/helpers'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import SliderContainer from './sliderContainer'
import { ContainerSimulator, PeriodoValorFinal, InputRange, TypeList } from './styles'
import { SuperAppInterProps } from 'src/pages/pra-voce/investimentos/meu-porquinho/types'

type resultadoMensalProps = {
  periodo: string;
  valorFinalSemInvetir: number;
  valorFinal: number;
}

const MeuPorquinhoSimulator = ({ dataLayer, setDataLayer, dobra }: SuperAppInterProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const MIN_VALUE = 1
  const MAX_VALUE = 9999999

  const [ value, setValue ] = useState(MIN_VALUE)
  const [ rangeValue, setRangeValue ] = useState<number>(MIN_VALUE)

  const resultadoMensal = [
    {
      periodo: '1 ano',
      valorFinalSemInvetir: value * 12,
      valorFinal: value * ((1 + 0.01) ** 12 - 1) / 0.01,
    },
    {
      periodo: '2 anos',
      valorFinalSemInvetir: value * 24,
      valorFinal: value * ((1 + 0.01) ** 24 - 1) / 0.01,
    },
    {
      periodo: '5 anos',
      valorFinalSemInvetir: value * 60,
      valorFinal: value * ((1 + 0.01) ** 60 - 1) / 0.01,
    },
    {
      periodo: '10 anos',
      valorFinalSemInvetir: value * 120,
      valorFinal: value * ((1 + 0.01) ** 120 - 1) / 0.01,
    },
    {
      periodo: '20 anos',
      valorFinalSemInvetir: value * 240,
      valorFinal: value * ((1 + 0.01) ** 240 - 1) / 0.01,
    },
  ]

  function currencyToFloat (currencyNumber: string) {
    const result =
    currencyNumber === ''
    ? 0
    : parseInt(currencyNumber.replace(/\D/g, '')) / 100
    return result
  }

  function changeInput (evt: ChangeEvent<HTMLInputElement>) {
    const inputValue = evt.target.value
    const insertValue = currencyToFloat(inputValue)
    setRangeValue(currencyToFloat(evt.target.value))
    setValue(insertValue)
  }

  return (
    <>
      <ContainerSimulator>
        <div className='simulator-header'>
          <p className='font-sora text-grayscale--500 fs-16 lh-20 fs-lg-20 lh-lg-25 text-center fw-600'>
            Insira um valor mensal
          </p>
          <div className='cifra'>
            <div className='line-bottom d-flex'>
              <span className='font-sora mr-2 fs-24 lh-3 fs-lg-32 lh-lg-40 fw-600'>R$</span>
              <input
                type='text'
                name='valor'
                maxLength={10}
                value={convertFloatToCurrencyString(value)}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  changeInput(evt)
                  setDataLayer(dataLayer)
                  sendDatalayerEvent({
                    section: dobra ? dobra : 'null',
                    section_name: 'Quanto deseja guardar por mês?',
                    element_action: 'click button',
                    element_name: 'Insira um valor mensal',
                    element_search: convertFloatToCurrencyString(value),
                    step: 'success',
                  })
                }}
                className='font-sora fs-24 lh-3 fs-lg-32 lh-lg-40 fw-600 w-100'
              />
            </div>
          </div>
          <span className='d-block font-sora text-grayscale--500 fs-16 lh-20 text-center fw-600'>Simulação</span>
        </div>

        <PeriodoValorFinal className='d-flex justify-content-between align-items-center'>
          <span className='text-grayscale--400 fs-14 lh-17 fw-600'>Período</span>
          <span className='text-grayscale--400 fs-14 lh-17 fw-600'>Valor Final</span>
        </PeriodoValorFinal>

        {resultadoMensal.map((item: resultadoMensalProps, index: number, resultado: resultadoMensalProps[]) => {
          return (
            <InputRange key={index}>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='text-grayscale--500 fs-14 lh-17'>{item.periodo}</span>
                <span className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 fw-600'>
                  R$ {convertFloatToCurrencyString(item.valorFinal)}
                </span>
              </div>
              <span className='range-slider'>
                <input
                  type='range'
                  name='range'
                  min={MIN_VALUE}
                  max={MAX_VALUE}
                  value={rangeValue}
                />
                <SliderContainer
                  rangeValue={item.valorFinal}
                  semInvestir={item.valorFinalSemInvetir}
                  value={value}
                  minValue={MIN_VALUE}
                  maxValue={resultado[4].valorFinal}
                />
              </span>
            </InputRange>
          )
        })}
        <TypeList>
          <li><span />Sem investir</li>
          <li><span className='green' />Investindo no Meu Porquinho</li>
        </TypeList>
      </ContainerSimulator>
      <div className='col-12 pt-2'>
        <p className='text-white fs-10 lh-12 mb-0 px-md-2 px-lg-0'>
          "A simulação não garante resultados futuros e leva em consideração um rendimento de 1% ao mês, sem desconto de impostos."
        </p>
      </div>
    </>
  )
}

export default MeuPorquinhoSimulator
