import styled from 'styled-components'
import { grayscale } from '../../../../../../styles/colors'
import { green } from 'src/styles/colors'
import { primary, sand } from 'src/styles/newColors'
import { device } from 'src/styles/breakpoints'

type DivPrincipalProps = {
  type?: ModalVariantsProps ;
}
type ModalVariantsProps = 'PF' | 'PJ' | 'US';

const backgrounds = {
  PF: grayscale[100],
  PJ: sand,
  US: primary[100],
}

export const ContentWrapper = styled.section`
  h1,h2,h3,h4,h5{
    font-family: Citrina, sans-serif;
  }
  p,span{
    font-family: Inter, sans-serif;
  }
  padding: 40px 0;
`

export const DivPrincipal = styled.div<{ type?: ModalVariantsProps}>`
  background-color: ${(props: DivPrincipalProps) => backgrounds[`${props.type ?? 'PF'}`] || grayscale[100]};
  padding: 24px;
  border-radius: 16px;
  transition: background-color 0.04s linear 0s; 
  
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px 32px;
  }
  
  @media ${device.desktopXXL}{
    justify-content: space-between;
    padding: 47px 57.5px;
    width: 100%;
  }

  @media ${device.desktopXXLL}{
    padding: 44px 81px;
    width: 90%;
  }
`

export const InformationsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media ${device.tablet}{
    padding: 0 24px 0 0;
  }
  
  @media (min-width: 1024px) {
    padding: 0 20px 0 0;
  }

  @media ${device.desktopXXL}{
    padding: 0;
  }

  .section__title{
    font-weight: 700;
    font-size: 28px;
    line-height: 33.6px;
    color: ${grayscale[500]};
    margin-bottom: 16px;

    @media (min-width: 1024px) {
      font-size: 40px;
      font-weight: 44px;
    }

    @media ${device.desktopXXLL}{
      font-size: 48px;
      line-height: 52.8px;
    }
  }

  .section__subtitle{
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: ${grayscale[500]};
    margin-bottom: 8px;
    
    @media ${device.tablet}{
      font-size: 14px;
      line-height: 19.94px;
    }

    @media (min-width: 1024px) {
      font-size: 16px;
      line-height: 19.36px;
    }

    @media ${device.desktopXXLL}{
      font-size: 18px;
      line-height: 21.78px;
    }
  }

  .section__benefits{
    @media ${device.tablet} {
      margin: 0 0 28px 0;
    }

    &__item{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      list-style: none;
      margin: 16px 0;

      @media ${device.tablet}{
        margin: 8px 0;
      }

      @media (min-width: 1024px) {
        margin: 10px 0;
      }

      @media ${device.desktopXXLL}{
        margin: 12px 0;
      }
      
      &__icon{
        width: 24px;
        margin-right: 8px;  
        
        @media ${device.tablet}{
          width: 16px;
        }

        @media ${device.desktopXXLL}{
          width: 18px;
        }

        &__image{
          width: 24px;
          height: 24px;
          
          @media ${device.tablet}{
            width: 16px ;
            height: 16px;
          }
          @media ${device.desktopXXLL}{
            width: 18px;
            height: 18px;
          }
        }
      }

      &__description{
        font-weight: 600;
        font-size: 16px;
        line-height: 19.36px;
        color: ${grayscale[500]};
        margin: 0;
        
        @media ${device.tablet}{
          font-size: 14px;
          line-height: 19.94px;
        }
  
        @media (min-width: 1024px) {
          font-size: 16px;
          line-height: 19.36px;
        }
  
        @media ${device.desktopXXLL}{
          font-size: 18px;
          line-height: 21.78px;
        }
      }
    }
  }

  .section__save__money__button{
    margin-top: 12px;
    @media ${device.tablet}{
      margin-top: 0;
    }
  }
`

export const InvestmentOffers = styled.div`
  @media ${device.tablet}{
    padding: 0 0 0 24px;    
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @media (min-width: 1024px) {
    padding: 0 0 0 20px;
  }

  @media ${device.desktopXXL}{
    padding: 0;
  }
`

export const Card = styled.div`
  background-color: white;
  padding: 0 16px;
  border-radius: 16px;
  border: 1px solid ${grayscale[200]};
  box-shadow: 0 8px 16px 2px rgba(22, 22, 22, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 184px;
  margin: 12px 0;

  @media ${device.tablet}{
    flex-direction: column;
    width: 250px;
    min-height: 228px;
  }


  @media (min-width: 1024px) {
    flex-direction: row ;
    width: 100%;
    min-height: 208px;
    padding: 0 26.5px;
  }

  @media ${device.desktopXXL}{
    width: 90%;
  }
  
  @media ${device.desktopXXLL}{
    width: 88.03%;
    min-height: 222.5px;
  }

  .card__icon{
    margin-right: 16px;
    width: 40%;

    @media ${device.tablet}{
      margin: 0 0 16px 0;
    }

    @media (min-width: 1024px) {
      width: 63%;
      margin: 0 24px 0 0;
    }
    
    @media ${device.desktopXXL}{
      width: 85%;
    }

    &__image{
      width: 100%;
      height: 100%;
    }
  }

  .card__texts{
    display: flex;
    flex-direction: column;
    width: 50%;

    @media ${device.tablet}{
      width: 100%;
      align-items: center;
    }

    @media (min-width: 1024px) {
      align-items: flex-start;
    }

    &__title{
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: ${grayscale[500]};
      text-align: left;
      margin-bottom: 8px;

      @media ${device.tablet}{
        margin: 0px 0 8px 0;
        text-align: center;
      }

      @media (min-width: 1024px) {
        text-align: left;
        font-size: 24px;
        line-height: 28.8px;
      }

      @media ${device.desktopXXLL}{
        font-size: 24px;
        line-height: 28.8px;
      }
    }
    
    &__initial__value{
      font-weight: 400;
      font-size: 16px;
      line-height: 19.36px;
      color: ${grayscale[400]};

      @media ${device.tablet}{
        text-align: center;
        margin: 0;
      }

      @media (min-width: 1024px) {
        text-align: left;
      }

      &--highlighted{
        display: block;
        font-family: Citrina, sans-serif;
        font-weight: 700;
        font-size: 24px;
        line-height: 28.8px;
        color: ${green[400]};
      }
    }
  }  
`
