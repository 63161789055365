import React from 'react'

import * as S from './style'
import { grayscale, red } from 'src/styles/colors'

interface IErrorsValidation {
  isValidPoints: (points: number) => void;
  isLessThanMinValue: (points: number) => void;
  [key: string]: Function;
}

interface IErrorMessages {
  isValidPoints: string;
  isLessThanMinValue: string;
}

const errorMessages: IErrorMessages = {
  isValidPoints: 'Escolha a cada 100 pontos',
  isLessThanMinValue: 'Mínimo 200 pontos',
}

const InterLoopSimulador = () => {
  const [ pointsInput, setPointsInput ] = React.useState<number>(600)
  const [ pointsValue, setPointsValue ] = React.useState<number>(0)
  const [ errorMessage, setErrorMessage ] = React.useState('')

  const calculatePoints = (points: number) => {
    const result = (points / 100) * 2
    setPointsValue(result)
  }

  const returnErrors = (points: number) => {
    switch (true) {
      case (+points % 100) !== 0:
        setErrorMessage(errorMessages.isValidPoints)
        break
      case (+points < 200):
        setErrorMessage(errorMessages.isLessThanMinValue)
        break
      default:
        setErrorMessage('')
        calculatePoints(points)
        break
    }
  }

  const handleInputChange = (points: number) => {
    setPointsInput(points)
    returnErrors(points)
  }

  React.useEffect(() => {
    handleInputChange(pointsInput)
  }, [ ])

  return (
    <S.CardBlock>
      <div>
        <p className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-400 font-sora'>Deseja trocar quantos pontos?</p>
      </div>
      <div>
        <S.InputContainer className='d-flex align-items-center flex-column'>
          <S.LoopInput type='text' name='points' id='points' value={pointsInput} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(+event.target.value.replace(/[^0-9]/g, ''))} />
          {errorMessage ? <S.FeedbackMessage className='fs-14 lh-17 fw-400 font-inter text-center' textColor={red[500]}>{errorMessage}</S.FeedbackMessage> : <S.FeedbackMessage className='fs-14 lh-17 fw-400 font-inter text-center' textColor={grayscale[400]}>Mínimo 200 pontos</S.FeedbackMessage>}
        </S.InputContainer>
      </div>
      <S.OutputBox className='d-flex flex-row justify-content-center align-items-center mt-3'>
        <div>
          <span className='fs-24 lh-30 fw-600 font-sora text-grayscale--400 d-block mr-2'>=</span>
        </div>
        <div className='d-flex flex-column'>
          <div>
            <S.OutputValue error={!!errorMessage} className='fs-24 lh-30 fw-600 font-sora text-center'>{`R$ ${pointsValue},00`}</S.OutputValue>
          </div>
          <span className='fs-12 lh-14 fw-400 text-grayscale--500 font-inter text-center'>no Meu Porquinho</span>
        </div>
      </S.OutputBox>
    </S.CardBlock>
  )
}

export default InterLoopSimulador
