import React from 'react'
import Img from 'gatsby-image'
import SectionComponent from 'src/components/SectionComponent'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import CarouselAcesseOappJSON from '../../assets/data/carousel-acesse-o-app.json'
import usePageQuery from '../../pageQuery'

import { CarouselAcesseOappProps } from '../../types'
import { ContainerCarousel } from './styles'

const CarouselAcesseOapp = () => {
  const data = usePageQuery()

  return (
    <SectionComponent
      id='como-investir-no-meu-porquinho'
      SectionStyles='pt-0 pb-5 bg-orange--extra'
      minHeight={{ sm: '662px', md: '481px', lg: '748px', xl: '812px' }}
    >
      <ContainerCarousel className='col-12'>
        <DefaultCarousel
          sm={{ items: 1 }}
          md={{ items: 1 }}
          lg={{ items: 1 }}
          xl={{ items: 1 }}
        >
          {CarouselAcesseOappJSON.map((item: CarouselAcesseOappProps, index: number) => (
            <div className='row' key={index}>
              <div className='col-12 col-md-6 col-lg-5 pr-md-0'>
                {item.subTitle && item.description !== '' &&
                  <>
                    <h2
                      className='font-citrina text-white fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-3'
                      dangerouslySetInnerHTML={ { __html: item.subTitle } }
                    />
                    <p
                      className='text-white fs-14 lh-17 fs-md-16 lh-md-20 mb-3 mb-md-4 pr-xl-5'
                      dangerouslySetInnerHTML={ { __html: item.description } }
                    />
                  </>
                }
                <h3
                  className='font-sora text-white fs-16 lh-20 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-400 mb-3'
                  dangerouslySetInnerHTML={ { __html: item.title } }
                />
              </div>
              <div className='col-12 col-md-6 col-xl-5'>
                <Img
                  fluid={data[item.image].fluid}
                  alt={item.altText}
                  className='float-lg-right'
                />
              </div>
            </div>
          ))}
        </DefaultCarousel>
      </ContainerCarousel>
    </SectionComponent>
  )
}

export default CarouselAcesseOapp
