import React, { useState, useEffect } from 'react'

interface ISliderContainer {
  rangeValue: number;
  semInvestir: number;
  value: number;
  minValue: number;
  maxValue: number;
}

const SliderContainer = ({ rangeValue, semInvestir, value, minValue, maxValue }: ISliderContainer) => {
  const [ percentageGreen, setPercentageGreen ] = useState<number>(0)
  const [ percentageGrey, setPercentageGrey ] = useState<number>(0)

  function calculatePercentageGreen (rangeValue: number) {
    const range = Math.round(maxValue - minValue)
    return Math.round((rangeValue - minValue) * 100 / range)
  }

  function calculatePercentageGrey (semInvestir: number) {
    const rangeGrey = Math.round(maxValue - minValue)
    return (semInvestir - minValue) * 100 / rangeGrey
  }

  useEffect(() => {
    setPercentageGreen(calculatePercentageGreen(rangeValue))
    setPercentageGrey(calculatePercentageGrey(semInvestir))
  }, [ value, minValue ])

  return (
    <span className='slider-container'>
      <span className='bar'>
        {value >= 0.01 && <span style={{ width: `${percentageGreen}%` }} />}
      </span>
      {value >= 0.01 && <span className='bar-btn' style={{ width: `${percentageGrey}%` }} />}
    </span>
  )
}

export default SliderContainer
