import { TabDefinition, TabPager } from '@interco/inter-ui/components/TabPager'
import React from 'react'
import * as Styles from './styles'
import * as Types from './types'
const PagerMeuPorquinho = ({
  setTypeModal,
  typeModal,
}: Types.PagerMeuPorquinhoProps) => {
  const tabDefinitions: TabDefinition[] = [
    {
      tabId: 'PF',
      label: 'Pra você',
      content: <></>,
    },
    {
      tabId: 'PJ',
      label: 'Empresas',
      content: <></>,
    },
    {
      tabId: 'US',
      label: 'Global',
      content: <></>,
    },
  ]

  const handleTabSelect = (tab: string | number) => {
    setTypeModal(tab)
  }

  return (
    <TabPager
      tabItemStyle={Styles.tabItem}
      tabListStyle={Styles.tabList}
      tabPanelStyle={{ ...Styles.tabPanel, flexDirection: undefined }}
      onTabSelect={handleTabSelect}
      tabDefinitions={tabDefinitions}
      currentTabId={typeModal as string}
    />
  )
}

export default PagerMeuPorquinho
