import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'

export const ContainerCarousel = styled.div`

  .react-multi-carousel-track {
    padding-bottom: 0;
  }
`

export const Card = styled.div`
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background: ${white};
  border: 1px solid ${grayscale[200]};
  min-height: 209px;
  margin-right: 16px;
`
