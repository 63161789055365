import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const ContainerCarousel = styled.div`

  img {
    width: 100%;
    height: 100%;
  }

  .react-multi-carousel-track {
    padding-bottom: 40px;
    align-items: center;
  }

  .react-multi-carousel-dot--active button {
      background: ${white};
  }

  .react-multiple-carousel__arrow {
    @media ${device.tablet} {
      bottom: 90px;
      z-index: 1;
    }
    @media ${device.desktopLG} {
      bottom: 202px;
    }
    @media ${device.desktopXL} {
      bottom: 183px;
    }

    svg {
      fill: ${white}
    }

    &--left {
      @media ${device.tablet} {
        left: -9px;
      }
      @media ${device.desktopXL} {
        left: -4px;
      }
    }
    &--right {
      @media ${device.tablet} {
        right: 49%;
      }
      @media ${device.desktopLG} {
        right: 58%;
      }
    }
  }

  .react-multi-carousel-dot-list {
    @media ${device.tablet} {
      bottom: 98px;
      right: 48%;
    }
    @media ${device.desktopLG} {
      bottom: 210px;
      right: 58%;
    }
    @media ${device.desktopXL} {
      bottom: 190px;
    }
  }
`
