import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ContainerBox = styled.div`

  &.grid-desktop-xxl {
    @media ${device.desktopXXXL} {
      max-width: 456px;
    }
  }
`
